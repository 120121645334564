const config = {
    apiKey: "AIzaSyDwR6z3tOQSBJVPK4T7Ctcxd6-jjpdKUpU",
    authDomain: "quick-facts-on-covax.firebaseapp.com",
    projectId: "quick-facts-on-covax",
    storageBucket: "quick-facts-on-covax.appspot.com",
    messagingSenderId: "973657789095",
    appId: "1:973657789095:web:5b1fbe3f56155134ca8182",
    measurementId: "G-E5D90SW9SY"
};

export default config;
