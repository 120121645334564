import React, {Component} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import QuickFacts from '../../Library/QuickFacts';
import Wall from './img/wall.png';
import Jab from './img/jab.png';

export default class Egypt extends Component {
    PropTypes = {
        // body: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            fact: '',
            clicked: false,
        }
    }

    onClick = (fact) => {
        this.setState({fact, clicked: true});
    }

    onMouseEnter = (fact) => {
        this.setState({fact});
    }

    onMouseLeave = () => {
        const {clicked} = this.state;
        if (!clicked) {
            this.setState({fact: ''});
        }
    }

    body = () => {
        return (
            <div className="facts">
                <bold>COVID-19 in Egypt</bold>
                <br/>
                <br/>
                Like many African countries, Egypt has
                {' '}
                <a href="http://documents1.worldbank.org/curated/en/280131589922657376/pdf/MENA-Crisis-Tracker-March-30-2021.pdf">
                    struggled mightily
                </a>
                {' '}
                to contain COVID-19.
                <br/>
                <br/>
                Among its over 100 million residents, the country has officially registered <b>204,256 confirmed COVID-19 cases (0.20% of the population)</b> and reported <b>12,123 deaths</b>.
                <br/>
                <br/>
                These statistics account for <b>Africa’s fourth-highest cumulative case total and second-highest</b>
                {' '}
                <a href="https://ourworldindata.org/coronavirus/country/egypt">
                    count of reported deaths
                </a>
                {' '}
                due to COVID-19.
                <br/>
                <br/>
                Yet, despite all the bad news, Egypt has found some forms of success in its pandemic response:
                <br/>
                <br/>
                Despite having the continent’s third-largest population, Egypt’s <b>rate of 1,996 cases per 1 million people</b> is lower than those of major African countries such as South Africa, Kenya and Morocco.
                <br/>
                <br/>
                Similarly, the country’s <b>7-day case total (4,892)</b> is
                {' '}
                <a href="https://coronavirus.jhu.edu/region/egypt">
                    substantially lower than its June 2020 peak
                </a>
                {' '}
                (10,635). Even more encouraging, its <b>7-day death rate (278)</b> has also fallen dramatically since its fellow June peak (618).
                <br/>
                <br/>
                If Egypt is to continue bringing the pandemic in check, these encouraging statistics must hold as the country kickstarts its sluggish vaccination effort.
                <br/>
                <br/>
                <b>Note: </b>Though Egypt is fairly regular in its reporting of case and death statistics, the country lags severely in its release of testing data.
                <br/>
                <br/>
                As Egypt’s testing figures haven't been updated in over 10 weeks (17 January), it’s impossible for the global scientific community to fully determine the state of the country’s pandemic response.
                <br/>
                <br/>
                <bold>Vaccine Approval in Egypt</bold>
                <br/>
                <br/>
                The Egyptian government approved the use of the <b>Anglo-Swedish AstraZeneca vaccine</b> and the <b>Chinese Sinopharm vaccine in January</b>, followed by the <b>Russian Sputnik V vaccine in February</b>.
                <br/>
                <br/>
                <div className="pic-source">
                    <img src={Wall} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: REUTERS
                </div>
                <br/>
                <br/>
                <bold>Vaccine Delivery to Egypt</bold>
                <br/>
                <br/>
                Following the approval of multiple COVID-19 vaccines in January and February, <b>Egypt has obtained 1,554,400 doses for administration</b>.
                <br/>
                <br/>
                Since December,
                {' '}
                <a href="https://www.reuters.com/article/uk-health-coronavirus-egypt/egypt-gets-more-doses-of-chinese-coronavirus-vaccine-idUSKBN2BC0D4">
                    the African country has received 650,000 doses of the Sinopharm vaccine
                </a>
                {' '}
                through donations from China (600,000) and the United Arab Emirates (50,000).
                <br/>
                <br/>
                In early February, COVAX also delivered a small allotment of 50,000 AstraZeneca doses,
                {' '}
                <a href="https://www.unicef.org/egypt/press-releases/854400-covid-19-vaccine-doses-shipped-covax-egypt">
                    followed by a mass shipment of 854,400 shots on 31 March
                </a>
                .
                <br/>
                <br/>
                As of 4 April, Egypt has yet to receive any doses of Russia’s Sputnik V Vaccine.
                <br/>
                <br/>
                <bold>Vaccine Administration in Egypt</bold>
                <br/>
                <br/>
                If there’s a glaring trouble spot in Egypt’s pandemic response, it’s
                {' '}
                <a href="http://documents1.worldbank.org/curated/en/280131589922657376/pdf/MENA-Crisis-Tracker-March-30-2021.pdf">
                    the country’s lethargic vaccination effort
                </a>
                .
                <br/>
                <br/>
                Despite its influx of donated vaccines, Egypt has been slow to vaccinate and even slower to release vaccination statistics.
                <br/>
                <br/>
                As of 30 January (last release of vaccination data), Egypt had only <b>administered 1,315 of its now 1,554,400 available COVID-19 vaccine doses</b>, translating to a <b>0.08% share of available vaccines administered</b>.
                <br/>
                <br/>
                Similarly, the country’s <b>current rates of both daily (&#60;0.01) and cumulative (&#60;0.01) doses offered per 100 people</b> lag behind other African countries and remain unreported since January.
                <br/>
                <br/>
                Further dovetailing with this lack of dosing data is an absence of information on the share of the population vaccinated.
                <br/>
                <br/>
                Identical on 4 April to 30 January (due to unreleased statistics), <b>less than 0.01% of the Egyptian population has received a single dose of a COVID-19 vaccine or been fully vaccinated</b>.
                <br/>
                <br/>
                If Egypt is to turn the tide of the pandemic, the country will need to boost its vaccination numbers and increase its data reporting to receive further help from COVAX.
                <br/>
                <br/>
                <div className="pic-source">
                    <img src={Jab} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: ASSOCIATED PRESS
                </div>
                <br/>
                <br/>
                <bold>COVAX Questions of The Week</bold>
                <br/>
                <br/>
                <b>1. How many countries administer the AstraZeneca vaccine?</b>
                <br/>
                <br/>
                As of 4 April,
                {' '}
                <a href="https://www.nytimes.com/interactive/2021/world/covid-vaccinations-tracker.html">
                    104 countries
                </a>
                {' '}
                offer AstraZeneca’s COVID-19 vaccine.
                <br/>
                <br/>
                <b>2. What are some of the significant issues affecting the COVAX initiative in developing countries?</b>
                <br/>
                <br/>
                According to this recent
                {' '}
                <a href="https://news.un.org/en/story/2021/04/1088932">
                    report from the United Nations
                </a>
                , significant challenges for COVAX in low-income countries include lack of funding, vaccine hesitancy and poor vaccination infrastructure.
                <br/>
                <br/>
                <b className="big">If you have questions about the COVAX initiative, please feel free to email us at <a
                    href="mailto:questions@quickfaxoncovax.com">questions@quickfaxoncovax.com</a>.</b>
            </div>
        );
    }

    cases = (
        <>
            <table>
                <tr>
                    <td>Total Cases</td>
                    <td className="quick-value" style={{minWidth: '100px'}}>204,256</td>
                </tr>
                <tr>
                    <td>7-Day Case Total (28 March - 4 April 2021)</td>
                    <td className="quick-value">4,892</td>
                </tr>
                <tr>
                    <td>Cases Per 1 Million</td>
                    <td className="quick-value">1,996</td>
                </tr>
                <tr>
                    <td>Share of Population to be Diagnosed with COVID-19</td>
                    <td className="quick-value">0.20%</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://coronavirus.jhu.edu/region/cambodia"
                >
                    Johns Hopkins University Coronavirus Resource Center
                </a>
                ,
                {' '}
                <a href="https://ourworldindata.org/grapher/total-covid-cases-deaths-per-million?tab=table&country=~OWID_WRL">
                    Our World in Data
                </a>
                ,
                {' '}
                <a href="http://documents1.worldbank.org/curated/en/280131589922657376/pdf/MENA-Crisis-Tracker-March-30-2021.pdf">
                    World Bank
                </a>
            </div>
        </>
    );

    deaths = (
        <>
            <table>
                <tr>
                    <td>Total</td>
                    <td className="quick-value">12,123</td>
                </tr>
                <tr>
                    <td>7-day Trend (28 March - 4 April 2021)</td>
                    <td className="quick-value">278</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://coronavirus.jhu.edu/region/cambodia"
                >
                    Johns Hopkins University Coronavirus Resource Center
                </a>
            </div>
        </>
    );

    approved = (
        <>
            <table>
                <tr>
                    <td>1. AstraZeneca</td>
                    <td className="quick-value">(United Kingdom & Sweden)</td>
                </tr>
                <tr>
                    <td>2. Sinopharm</td>
                    <td className="quick-value">(China)</td>
                </tr>
                <tr>
                    <td>3. Sputnik V</td>
                    <td className="quick-value">(Russia)</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://www.reuters.com/article/uk-health-coronavirus-egypt-vaccine/egypt-begins-vaccine-rollout-to-wider-population-idUSKCN2AW15U"
                >
                    Reuters
                </a>
            </div>
        </>
    );

    delivered = (
        <>
            <table>
                <tr>
                    <td>AstraZeneca</td>
                    <td className="quick-value">904,400 (by COVAX)</td>
                </tr>
                <tr>
                    <td>Sinopharm</td>
                    <td className="quick-value">650,000 (by China and the UAE)</td>
                </tr>
                <tr>
                    <td>Sputnik V</td>
                    <td className="quick-value">N/A</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://www.reuters.com/article/uk-health-coronavirus-egypt/egypt-gets-more-doses-of-chinese-coronavirus-vaccine-idUSKBN2BC0D4"
                >
                    Reuters
                </a>
                ,
                {' '}
                <a
                    href="https://news.trust.org/item/20210331215619-zy4e1"
                >
                    Trust
                </a>
                ,
                {' '}
                <a
                    href="https://www.unicef.org/egypt/press-releases/854400-covid-19-vaccine-doses-shipped-covax-egypt"
                >
                    UNICEF
                </a>
            </div>
        </>
    );

    administered = (
        <>
            <table>
                <tr>
                    <td>Total</td>
                    <td className="quick-value">1,315</td>
                </tr>
                <tr>
                    <td>Share of Vaccines Administered/Delivered</td>
                    <td className="quick-value">0.08%</td>
                </tr>
                <tr>
                    <td>Daily Doses Per 100 People</td>
                    <td className="quick-value">&#60;0.01</td>
                </tr>
                <tr>
                    <td>Cumulative Doses Per 100 People</td>
                    <td className="quick-value">&#60;0.01</td>
                </tr>
                <tr>
                    <td>Share of Population with One Dose</td>
                    <td className="quick-value">&#60;0.01%</td>
                </tr>
                <tr>
                    <td>Share of Population Fully Vaccinated</td>
                    <td className="quick-value">&#60;0.01%</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://coronavirus.jhu.edu/region/egypt"
                >
                    Johns Hopkins University Coronavirus Resource Center
                </a>
                ,
                {' '}
                <a href="https://ourworldindata.org/coronavirus/country/egypt#how-many-covid-19-vaccine-doses-have-been-administered-in-total">
                    Our World in Data
                </a>
                ,
                {' '}
                <a href="https://covid19.who.int/region/emro/country/eg/">
                    WHO
                </a>
                ,
                {' '}
                <a href="http://documents1.worldbank.org/curated/en/280131589922657376/pdf/MENA-Crisis-Tracker-March-30-2021.pdf">
                    World Bank
                </a>
            </div>
        </>
    );

    render() {
        const {body, cases, deaths, approved, delivered, administered} = this;
        const quickFacts = {cases, deaths, approved, delivered, administered};
        return (
            <div className="blog-post">
                <div className="space-between">
                    <div>
                        4 April 2021
                    </div>
                    <div className="text-right">
                        Vol. 4
                    </div>
                </div>
                <div className="title">
                    <div className="flex justify-content-center align-items-center" style={{flex: '1'}}>
                        <div>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/1200px-Flag_of_Egypt.svg.png"
                                alt=""
                            />
                        </div>
                        <h1>
                            EGYPT
                        </h1>
                        <div>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/1200px-Flag_of_Egypt.svg.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <br/>
                <QuickFacts
                    {...quickFacts}
                    map="https://africa.cgtn.com/wp-content/photo-gallery/2019/11/gettyimages-163113483-170667a.jpg"
                    src="PHOTO: GETTY"
                />
                <br/>
                <br/>
                {body()}
            </div>
        );
    }
}
