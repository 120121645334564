import React, {Component} from 'react';
import Logo from '../../assets/img/logo.svg';
import {Accordion, Card} from 'react-bootstrap';
// import BlogPost from './assets/BlogPost';
import './index.scss';

export default class COVAXEssentials extends Component {
    // defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: loadData,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice"
    //     }
    // };

    render() {
        return (
            <>
                <div className="blog-post">
                    <div className="facts">
                        <bold>
                            COVAX FAQ
                        </bold>
                        <br/>
                        <br/>
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    1. What is COVAX?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>COVAX is a global initiative founded by
                                        {' '}
                                        <a href="https://www.unicef.org/supply/covax-ensuring-global-equitable-access-covid-19-vaccines">
                                            UNICEF
                                        </a>
                                        ,
                                        {' '}
                                        <a href="https://www.gavi.org/vaccineswork/covax-explained">
                                            Gavi, the Vaccine Alliance
                                        </a>
                                        ,
                                        {' '}
                                        <a href="https://www.who.int/initiatives/act-accelerator/covax">
                                            the World Health Organization
                                        </a>
                                        ,
                                        {' '}
                                        <a href="https://cepi.net/COVAX/">
                                            the Coalition for Epidemic Preparedness Innovations
                                        </a>
                                        {' '}
                                        and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    2. Which countries will benefit from COVAX?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <a href="https://www.bbc.com/news/world-55795297">
                                            COVAX aims to provide free vaccinations
                                        </a>
                                        {' '}
                                        to at least 20% of the population in the world’s 92 poorest countries by the conclusion of 2021.
                                        <br/>
                                        <br/>
                                        More than 90 middle and high-income countries have also ordered vaccines from COVAX, though they will have to pay for them.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    3. Which COVID-19 vaccines will COVAX use?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>Though the organizations behind COVAX continue to invest in several emerging global vaccines (Johnson and Johnson, Novavax, etc.), the majority of the COVAX vaccine supply in the first half of 2021 will come from
                                        {' '}
                                        <a href="https://www.astrazeneca.com/media-centre/press-releases/2021/astrazeneca-advances-mass-global-rollout-of-covid-19-vaccine-through-covax.html">
                                            AstraZeneca
                                        </a>
                                        {' '}
                                        -- an Anglo-Swedish pharmaceutical and biotechnological company.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    4. Who funds COVAX?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        COVAX has raised over $6 billion in donations from governments, foundations, corporations and philanthropic organizations.
                                        <br/>
                                        <br/>
                                        The
                                        {' '}
                                        <a href="https://www.statista.com/chart/24244/donations-to-covax-by-country/">
                                            largest donations to COVAX
                                        </a>
                                        {' '}
                                        have come from wealthy countries like the United States, Germany and the United Kingdom.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    5. How many COVID-19 vaccines has COVAX delivered?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>As of
                                        {' '}
                                        <a href="https://www.who.int/news/item/08-04-2021-covax-reaches-over-100-economies-42-days-after-first-international-delivery">
                                            April 2021
                                        </a>
                                        , COVAX has delivered nearly 40 million vaccine doses to over 100 economies across six continents.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                    6. How has COVAX prioritized low and middle-income countries in its vaccine rollout?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        As of
                                        {' '}
                                        <a href="https://www.who.int/news/item/08-04-2021-covax-reaches-over-100-economies-42-days-after-first-international-delivery">
                                            April 2021
                                        </a>
                                        , 61 of the 100+ countries to receive COVAX vaccines are low and middle-income countries whose doses are
                                        {' '}
                                        <a href="https://www.gavi.org/gavi-covax-amc">
                                            donor-funded
                                        </a>
                                        .
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                    7. What are good resources for updates on COVAX?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>Reliable sources for COVAX updates include the
                                        {' '}
                                        <a href="https://www.who.int/initiatives/act-accelerator/covax">
                                            World Health Organization (WHO)
                                        </a>
                                        ,
                                        {' '}
                                        <a href="https://www.gavi.org/vaccineswork/covax-explained">
                                            Gavi, the Vaccine Alliance
                                        </a>
                                        ,
                                        {' '}
                                        <a href="https://www.unicef.org/supply/covax-ensuring-global-equitable-access-covid-19-vaccines">
                                            UNICEF
                                        </a>
                                        {' '}
                                        and
                                        {' '}
                                        <a href="https://cepi.net/COVAX/">
                                            the Coalition for Epidemic Preparedness Innovations
                                        </a>
                                        .</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                    8. What are good resources for country-specific data on COVID-19 cases, deaths and vaccinations?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                    <Card.Body>Reliable sources for COVID-19 data include
                                        {' '}
                                        <a href="https://coronavirus.jhu.edu/map.html">
                                            Johns Hopkins University Coronavirus Resource Center
                                        </a>
                                        {' '}
                                        and
                                        {' '}
                                        <a href="https://ourworldindata.org/coronavirus">
                                            Our World in Data
                                        </a>
                                        .</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <br/>
                        <br/>


                        <Accordion
                            title="What is COVAX?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <Accordion
                            title="Which countries will benefit from COVAX?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <Accordion
                            title="Which COVID-19 vaccines will COVAX use?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <Accordion
                            title="Who funds COVAX?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <Accordion
                            title="How many COVID-19 vaccines has COVAX delivered?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <Accordion
                            title="What is the ratio of low and middle-income countries to high-income countries that have received COVAX vaccines?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <Accordion
                            title="What are good resources for updates on COVAX?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <Accordion
                            title="What are good resources for country-specific data on COVID-19 cases, deaths and vaccinations?"
                            content="COVAX is a global initiative founded by UNICEF, Gavi, the Vaccine Alliance, the World Health Organization, the Coalition for Epidemic Preparedness Innovations and other international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide."
                        />
                        <b className="big">If you have questions about the COVAX initiative, please feel free to email us at <a
                            href="mailto:questions@quickfaxoncovax.com">questions@quickfaxoncovax.com</a>.</b>
                    </div>
                </div>
            </>
        );
    }
}
