import {FirestoreMutation} from '@react-firebase/firestore';
import React from 'react';
import {v4 as uuid} from 'uuid';
import './assets/style/index.scss';

export default class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribed: false,
            email: '',
        };
    }

    render() {
        const {subscribed, email} = this.state;
        return (
            <div className="m-5">
                <FirestoreMutation type="set" path={`/subscribers/${uuid()}`}>
                    {({runMutation}) => {
                        return (
                            !subscribed ?
                                <div>
                                    Do you want to subscribe for weekly updates from Quick Facts on COVAX?
                                    <br/>
                                    <br/>
                                    <input
                                        type="text"
                                        value={email}
                                        placeholder="Email"
                                        onChange={(e) => this.setState({email: e.target.value})}
                                    />
                                    <button
                                        onClick={() => {
                                            runMutation({
                                                email: email
                                            }).then(res => {
                                                this.setState({subscribed: true})
                                            });
                                        }}
                                    >
                                        Subscribe
                                    </button>
                                </div>
                                :
                                <>
                                    Thanks for subscribing!
                                </>
                        );
                    }}
                </FirestoreMutation>
            </div>
        );
    }
}
