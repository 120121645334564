import React from 'react';
import World from '../../../assets/img/world.svg';
import { withRouter } from 'react-router-dom';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.location.pathname,
        }
    }

    render() {
        return (
            <div className="nav">
                <hr className="footer-line-left"/>
                <div id="world">
                    <img src={World} alt=""/>
                </div>
                <hr className="footer-line-right"/>
            </div>
        );
    }
}

export default withRouter(Footer);
