import React, {useState} from 'react';
import Ceremony from '../../Country Breakdowns/Cambodia/img/ceremony.png';
import Exit from './img/exit.svg';
import './index.scss';

const QuickFacts = ({cases, deaths, approved, delivered, administered, map, src}) => {
    const [fact, setFact] = useState('');
    function factMap(fact) {
        const mapping = {
            'Cases': cases,
            'Deaths': deaths,
            'Vaccines Approved': approved,
            'Vaccines Delivered': delivered,
            'Vaccines Administered': administered,
        };
        return mapping[fact] || '';
    };

    return (
        <div className="facts">
            <bold>Quick Facts</bold>
            <div className="quick-facts">
                <div className="facts-menu">
                    <div
                        className="menu-item"
                        onClick={() => setFact('Cases')}
                    >
                        Cases
                    </div>
                    <div
                        className="menu-item"
                        onClick={() => setFact('Deaths')}
                    >
                        Deaths
                    </div>
                    <div
                        className="menu-item"
                        onClick={() => setFact('Vaccines Approved')}
                    >
                        Vaccines Approved
                    </div>
                    <div
                        className="menu-item"
                        onClick={() => setFact('Vaccines Delivered')}
                    >
                        Vaccines Delivered
                    </div>
                    <div
                        className="menu-item"
                        onClick={() => setFact('Vaccines Administered')}
                    >
                        Vaccines Administered
                    </div>
                    <div
                        className="overlay"
                        style={{opacity: fact !== '' ? '1' : '0', visibility: fact !== '' ? 'visible': 'hidden'}}
                    >
                        <div id="exit">
                            <img
                                src={Exit} alt=""
                                onClick={() => setFact('')}
                            />
                        </div>
                        <h1>{fact}</h1>
                        {factMap(fact)}
                    </div>
                </div>
                <div className="pic-source text-right">
                    <img
                        src={map}
                        alt=""
                    />
                </div>
            </div>
            <div className="pic-source text-right">
                {src}
            </div>
        </div>
    );
};

export default QuickFacts;
