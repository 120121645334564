import React, {Component} from 'react';
import WorldHealth from './assets/img/world-health.png';
import Accelerator from './assets/img/accelerator.png';
import Jab from './assets/img/jab.png';

export default class COVAXSeeksFurtherFunding extends Component {
    PropTypes = {
        // body: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            fact: '',
            clicked: false,
        }
    }

    onClick = (fact) => {
        this.setState({fact, clicked: true});
    }

    onMouseEnter = (fact) => {
        this.setState({fact});
    }

    onMouseLeave = () => {
        const {clicked} = this.state;
        if (!clicked) {
            this.setState({fact: ''});
        }
    }

    body = () => {
        return (
            <div className="facts">
                <div className="pic-source" style={{marginTop: '50px'}}>
                    <img src={WorldHealth} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: WORLD HEALTH ORGANIZATION
                </div>
                <br/>
                <br/>
                As COVAX continues its effort to vaccinate the world against COVID-19, the organizations behind the initiative are calling for further donations from governments, foundations, corporations and philanthropic organizations worldwide.
                <br/>
                <br/>
                Let’s break down the donations COVAX has received so far and why the initiative is seeking additional funds:
                <br/>
                <br/>
                <bold>Current Funding</bold>
                <br/>
                <br/>
                As of 18 April 2021, COVAX has amassed over $6.35 billion in funding to support its vaccination efforts.
                <br/>
                <br/>
                These funds have come by way of donations from myriad sources, including wealthy governments and a mix of privately-funded companies and charitable organizations.
                <br/>
                <br/>
                While figures aren’t updated regularly, data shows
                {' '}
                <a href="https://www.statista.com/chart/24244/donations-to-covax-by-country/">
                    the most prominent backers of the initiative
                </a>
                {' '}
                are countries like the United States ($2.5 billion), Germany (~$1 billion) and the United Kingdom ($735 million).
                <br/>
                <br/>
                Other countries like Japan, Canada, Saudi Arabia and Norway are also financial supporters of the initiative, though their donations account for a much smaller percentage of total funding. The European Union, a collection of 27 countries, falls into this category as well.
                <br/>
                <br/>
                The largest non-governmental donors to COVAX are an array of companies and organizations from the private and philanthropic sectors.
                <br/>
                <br/>
                Chief among these is the Bill and Melinda Gates Foundation, which was instrumental in commissioning COVAX and has contributed over $150 million in donated funds to the initiative.
                <br/>
                <br/>
                While too numerous to name, many other major sources of non-governmental funding have also provided support through direct donations to
                {' '}
                <a href="https://www.unicef.org/supply/covax-ensuring-global-equitable-access-covid-19-vaccines">
                    UNICEF
                </a>
                ,
                {' '}
                <a href="https://www.gavi.org/vaccineswork/covax-explained">
                    Gavi, the Vaccine Alliance
                </a>
                ,
                {' '}
                <a href="https://www.who.int/initiatives/act-accelerator/covax">
                    the World Health Organization
                </a>
                {' '}
                and
                {' '}
                <a href="https://cepi.net/COVAX/">
                    the Coalition for Epidemic Preparedness Innovations
                </a>
                .
                <br/>
                <br/>
                <div className="pic-source" style={{marginTop: '50px'}}>
                    <img src={Accelerator} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: WORLD HEALTH ORGANIZATION
                </div>
                <br/>
                <br/>
                <bold>Desired Funds</bold>
                <br/>
                <br/>
                Though $6+ billion is undoubtedly
                {' '}
                <a href="https://www.kff.org/coronavirus-covid-19/issue-brief/covax-and-the-united-states/#:~:text=2022-,Financing,funding%20to%20support%20its%20efforts.">
                    a sizable financial base
                </a>
                , COVAX leaders have indicated that the initiative needs further funding to accomplish its goals.
                <br/>
                <br/>
                In a
                {' '}
                <a href="https://www.reuters.com/business/healthcare-pharmaceuticals/global-covid-19-vaccine-supply-incredibly-tight-covax-needs-funds-gavi-2021-04-15/">
                    statement last week
                </a>
                , Gavi, the Vaccine Alliance CEO Seth Berkley admitted that COVAX vaccine supplies are “incredibly tight” and that money is beginning to run out.
                <br/>
                <br/>
                According to Berkley, the organizations behind COVAX are currently struggling to purchase more doses for future delivery to low and middle-income countries in desperate need.
                <br/>
                <br/>
                Due to
                {' '}
                <a href="https://quickfactsoncovax.com/india-curbs-vaccine-exports">
                    supply and distribution issues
                </a>
                {' '}
                and both expected and unexpected difficulties of its vaccine rollout, the initiative is facing a potential financial crisis:
                <br/>
                <br/>
                According to Berkley, COVAX “desperately needs commitments of a further $2 billion from donors and $1 billion from countries supported by multilateral development banks. Included in the $2 billion [COVAX] asks for is $150 million from the private sector."
                <br/>
                <br/>
                Only time will tell if these funds can be acquired soon enough to help COVAX hit its target of 2 billion vaccines delivered, but the organizations behind the global rollout have certainly made the call for action.
                <br/>
                <br/>
                <div className="pic-source" style={{marginTop: '50px'}}>
                    <img src={Jab} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: ANDREW BROOKS/GETTY IMAGES
                </div>
                <br/>
                <br/>
                <bold>Impact on COVAX</bold>
                <br/>
                <br/>
                As we’ve discussed in past articles, COVAX is entirely dependent on the support of outside donors to fund its efforts.
                <br/>
                <br/>
                While a multitude of public and private backers rushed to support the initiative’s rollout in the past year, it’s becoming clear that financial and logistical setbacks are taking their toll.
                <br/>
                <br/>
                To COVAX’s advantage, the initiative has already displayed the capacity to broadcast and garner support for its mission, as evidenced by the billions of dollars already received.
                <br/>
                <br/>
                This capability, in tandem with
                {' '}
                <a href="https://www.who.int/news/item/08-04-2021-covax-reaches-over-100-economies-42-days-after-first-international-delivery">
                    COVAX’s early successes
                </a>
                , serves as a proof of concept for its efficacy.
                <br/>
                <br/>
                To COVAX’s disadvantage, however, are the continued
                {' '}
                <a href="https://unctad.org/news/covid-19s-economic-fallout-will-long-outlive-health-crisis-report-warns">
                    economic struggles
                </a>
                {' '}
                of countries and private/philanthropic organizations throughout the world.
                <br/>
                <br/>
                At a time when most governments and organizations face severe financial strife, not to mention the human toll of the COVID-19 pandemic, it’s understandably challenging to secure hundreds of millions of dollars from philanthropic and non-philanthropic sources.
                <br/>
                <br/>
                Regardless, the situation is clear: COVAX still needs money, and it will have to rely on a multitude of global sources to procure it.
                <br/>
                <br/>
                <bold>COVAX Questions of The Week</bold>
                <br/>
                <br/>
                <b>1. How many COVID-19 vaccines has COVAX delivered?</b>
                <br/>
                <br/>
                As of
                {' '}
                <a href="https://www.who.int/news/item/08-04-2021-covax-reaches-over-100-economies-42-days-after-first-international-delivery">
                    April 2021
                </a>
                , COVAX has delivered nearly 40 million vaccine doses to over 100 economies across six continents.
                <br/>
                <br/>
                <b>2. How has COVAX prioritized low and middle-income countries in its vaccine rollout?</b>
                <br/>
                <br/>
                As of
                {' '}
                <a href="https://www.who.int/news/item/08-04-2021-covax-reaches-over-100-economies-42-days-after-first-international-delivery">
                    April 2021
                </a>
                , 61 of the 100+ countries to receive COVAX vaccines are low and middle-income countries whose doses are
                {' '}
                <a href="https://www.gavi.org/gavi-covax-amc">
                    donor-funded
                </a>
                .
                <br/>
                <br/>
                <b className="big">If you have questions about the COVAX initiative, please feel free to email us at <a
                    href="mailto:questions@quickfaxoncovax.com">questions@quickfaxoncovax.com</a>.</b>
            </div>
        );
    }

    render() {
        const
            {body} = this;
        return (
            <div className="blog-post">
                <div className="space-between">
                    <div>
                        18 April 2021
                    </div>
                    <div className="text-right">
                        Vol. 5
                    </div>
                </div>
                <div className="title">
                    <h1>COVAX Seeks Further Funding</h1>
                </div>
                {body()}
            </div>
        );
    }
}
