import React, {Component} from 'react';
import EUVaccine from './assets/img/eu-vaccine.png';
import ItalyVaccine from './assets/img/italy-vaccine.png';

export default class EUAstrazencaControversy extends Component {
    PropTypes = {
        // body: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            fact: '',
            clicked: false,
        }
    }

    onClick = (fact) => {
        this.setState({fact, clicked: true});
    }

    onMouseEnter = (fact) => {
        this.setState({fact});
    }

    onMouseLeave = () => {
        const {clicked} = this.state;
        if (!clicked) {
            this.setState({fact: ''});
        }
    }

    body = () => {
        return (
            <div className="facts">
                <div className="pic-source" style={{marginTop: '50px'}}>
                    <img src={EUVaccine} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: RAWF8/GETTY IMAGES
                </div>
                <br/>
                <br/>
                In the week since our last post, a political storm brewed in the EU surrounding the AstraZeneca vaccine
                -- a primary feature of the COVAX initiative.
                <br/>
                <br/>
                Let’s break down the controversy’s genesis, resolution and future implications on COVAX and global
                vaccinations at large:
                <br/>
                <br/>
                <bold>How It Started</bold>
                <br/>
                <br/>
                Amid a lethargic vaccine rollout that saw EU vaccination rates fall far behind those of the United
                States and the United Kingdom, things went from bad to worse for Europe last week.
                <br/>
                <br/>
                Following
                {' '}
                <a href="https://www.newsweek.com/denmark-reports-one-death-after-use-astrazeneca-vaccine-will-maintain-suspension-1577577">
                    more than 30 claims
                </a>
                {' '}
                that the AstraZeneca vaccine caused life-threatening blood clots and irregular bleeding, a mix of
                prominent and smaller EU countries hastily suspended administration of the vaccine:
                <br/>
                <br/>
                <div className="pic-source">
                    <img
                        src="https://cdn.statcdn.com/Infographic/images/normal/24420.jpeg"
                        alt="Infographic: Which Countries Have Stopped Using The AstraZeneca Vaccine? | Statista"
                        width="100%" height="auto"
                        style={{height: 'auto !important', maxWidth: '960px'}}
                    />
                    <br/>
                    PHOTO: STATISTA
                </div>
                <br/>
                <br/>
                This development quickly sent the European Union and the international scientific community
                {' '}
                <a href="https://www.bloomberg.com/news/articles/2021-03-17/europe-s-latest-vaccine-controversy-risks-being-a-crisis-too-far">
                    into a tailspin
                </a>
                .
                <br/>
                <br/>
                Proponents of AstraZeneca immediately jumped to the defense of their shot, EU and global scientific
                agencies initiated investigations and the world watched as its most prominent COVID-19 vaccine hung in
                the balance.
                <br/>
                <br/>
                On a continent hampered by vaccine hesitancy and stumbling rollouts, many worried that public confidence
                in vaccinations would suffer irrevocable damage.
                <br/>
                <br/>
                <b>Note: </b>The above chart from Statista represents global pauses in AstraZeneca vaccinations as of 16
                March 2021.
                <br/>
                <br/>
                <bold>How It Resolved</bold>
                <br/>
                <br/>
                Within the week, the majority of EU fears surrounding the AstraZeneca vaccine resolved as quickly and
                forcefully as they arose.
                <br/>
                <br/>
                Following days of extensive investigation, the European Medicines Agency (EMA), supported by the WHO, the
                Medicines and Healthcare products Regulatory Agency and others, found the vaccine to be
                {' '}
                <a href="https://www.bbc.com/news/world-europe-56440139">
                    “safe and effective” and “not associated” with troubling blood clots
                </a>
                .
                <br/>
                <br/>
                After the announcement, influential European leaders throughout the bloc began an immediate backpedal,
                desperately hoping to revive public confidence in the AstraZeneca vaccine before reapproval.
                <br/>
                <br/>
                From French Prime Minister Jean Castex getting his first AstraZeneca dose on television to German
                Chancellor Angela Merkel intimating she would take the shot herself, the political attitude in the EU’s
                two largest countries began an abrupt, 180-degree turn.
                <br/>
                <br/>
                Throughout Europe, other major countries followed suit, as
                {' '}
                <a href="https://www.nytimes.com/2021/03/19/world/europe/coronavirus-vaccine-europe-astrazeneca.html">
                    Italy, Spain and others soon joined France and Germany
                </a>
                {' '}
                in the resumption of their AstraZeneca vaccination programs.
                <br/>
                <br/>
                <bold>Where Things Stand</bold>
                <br/>
                <br/>
                Though the majority of European leaders once again publicly support AstraZeneca, a few countries have yet
                to reinstate the vaccine following the positive EMA findings.
                <br/>
                <br/>
                In the
                {' '}
                <a href="https://www.wsj.com/livecoverage/covid-2021-03-19/card/lwroZi9eJM7Yvxz7jv7F">
                    Nordic countries of Denmark, Finland, Norway and Sweden
                </a>
                , politicians are expressing caution, continuing to conduct internal investigations on the connection
                between AstraZeneca vaccinations and blood clots before reinstatement.
                <br/>
                <br/>
                Meanwhile, other European officials worry that last week’s events
                {' '}
                <a href="https://www.nytimes.com/2021/03/16/briefing/blood-clots-oscar-nominees-opioid-purdue.html">
                    may have dealt a critical blow to vaccine confidence
                </a>
                {' '}
                among citizens in skeptical countries like France, Poland and Sweden.
                <br/>
                <br/>
                For now, though, the only certainty is that the majority of the EU once again will administer the
                AstraZeneca vaccine, an essential component of the bloc’s desperate fight against impending case waves
                and lengthening lockdowns.
                <br/>
                <br/>
                <div className="pic-source" style={{marginTop: '50px'}}>
                    <img src={ItalyVaccine} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: REUTERS
                </div>
                <br/>
                <br/>
                <bold>Impact on COVAX</bold>
                <br/>
                <br/>
                Though all but one COVAX country (Moldova) lies outside Europe, vaccine-needy countries worldwide
                breathed a sigh of relief following the EU’s reapproval of AstraZeneca’s shot.
                <br/>
                <br/>
                As most COVAX recipients still await
                {' '}
                <a href="https://www.astrazeneca.com/media-centre/press-releases/2021/astrazeneca-advances-mass-global-rollout-of-covid-19-vaccine-through-covax.html">
                    much-needed shipments of vaccines
                </a>
                {' '}
                from the Anglo-Swedish biopharmaceutical company, the last thing any of them needed was further
                skepticism and delay.
                <br/>
                <br/>
                Time will tell whether global confidence in the AstraZeneca vaccine will reach its previously-high
                levels, but many low and middle-income countries are likely eager to receive and distribute as many shots
                as possible.
                <br/>
                <br/>
                <bold>COVAX Questions of The Week</bold>
                <br/>
                <br/>
                <b>1. How many vaccines will be distributed through COVAX this year?</b>
                <br/>
                <br/>
                The COVAX initiative hopes to deliver
                {' '}
                <a href="https://www.who.int/news/item/22-01-2021-covax-announces-new-agreement-plans-for-first-deliveries">
                    2 billion COVID-19 vaccine doses by the end of 2021
                </a>
                ,
                {' '}
                with 1.3 billion of those earmarked for 92 lower-income countries.
                <br/>
                <br/>
                <b>2. Which country has the fastest COVID-19 vaccination rollout?</b>
                <br/>
                <br/>
                <a href="https://www.nytimes.com/interactive/2021/world/covid-vaccinations-tracker.html">
                    Israel
                </a>
                {' '}
                -- the Middle Eastern country has administered one vaccine dose to 58% of its population and fully
                vaccinated 51% of its citizens for a rate of 109 shots per 100 people.
                <br/>
                <br/>
                <b className="big">If you have questions about the COVAX initiative, please feel free to email us at <a
                    href="mailto:questions@quickfaxoncovax.com">questions@quickfaxoncovax.com</a>.</b>
            </div>
        );
    }

    render() {
        const
            {body} = this;
        return (
            <div className="blog-post">
                <div className="space-between">
                    <div>
                        21 March 2021
                    </div>
                    <div className="text-right">
                        Vol. 2
                    </div>
                </div>
                <div className="title">
                    <h1>EU AstraZeneca Controversy</h1>
                </div>
                {body()}
            </div>
        );
    }
}
