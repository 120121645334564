import {FirestoreProvider} from '@react-firebase/firestore';
import firebase from 'firebase';
import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import '../../assets/styles/index.scss';
import About from '../About';
import Cambodia from '../Country Breakdowns/Cambodia';
import Egypt from '../Country Breakdowns/Egypt';
import COVAXEssentials from '../COVAXEssentials';
import COVAXSeeksFurtherFunding from '../COVAXSeeksFurtherFunding';
import EUAstrazencaControversy from '../EUAstrazenecaControversy';
import IndiaCurbsVaccineExports from '../IndiaCurbsVaccineExports';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import Subscribe from '../Subscribe';
import config from './config';

export default class App extends Component {
    render() {
        return (
            <FirestoreProvider {...config} firebase={firebase}>
                <Router>
                    <div className="App">
                        <div className="home">
                            <div className="text-left rotate-45">
                                <a
                                    id="subscribe"
                                    href="/subscribe"
                                >
                                    SUBSCRIBE
                                </a>
                            </div>
                            <Header/>
                            <Switch>
                                <Route path="/about" component={About} exact/>
                                <Route path="/cambodia" component={Cambodia} exact/>
                                <Route path="/egypt" component={Egypt} exact/>
                                <Route path="/covax-essentials" component={COVAXEssentials} exact/>
                                <Route path="/covax-seeks-further-funding" component={COVAXSeeksFurtherFunding} exact />
                                <Route path="/subscribe" component={Subscribe} exact/>
                                <Route path="/eu-astrazeneca-controversy" component={EUAstrazencaControversy} exact/>
                                <Route path="/india-curbs-vaccine-exports" component={IndiaCurbsVaccineExports} exact/>
                                <Redirect to="/eu-astrazeneca-controversy"/>
                            </Switch>
                            <Footer/>
                        </div>
                    </div>
                </Router>
            </FirestoreProvider>
        );
    }
}
