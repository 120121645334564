import React, {Component} from 'react';
import Logo from '../../assets/img/logo.svg';
// import BlogPost from './assets/BlogPost';
import LinkedIn from '../../assets/img/linkedin.png';
import AnnenbergMedia from '../../assets/img/annenberg-media.png';
import DT from '../../assets/img/dt.png';

export default class COVAXEssentials extends Component {
    // defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: loadData,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice"
    //     }
    // };

    render() {
        return (
            <>
                <div className="blog-post">
                    <div className="facts">
                        <bold>
                            About the Author
                        </bold>
                        <br/>
                        <br/>
                        Spencer Kelley is a lifelong writer with a
                        passion for creating engaging, entertaining content.
                        <br/>
                        <br/>
                        Soon to complete a BA in Journalism from the University of Southern California, he blends his
                        style of focused storytelling with a keen eye on international media, politics and development.
                        <br/>
                        <br/>
                        Hoping to combine his business and communication skills with a knowledge of foreign affairs,
                        Spencer will begin a master's in International Relations at King's College London in autumn
                        2021.
                        <br/>
                        <br/>
                        In the meantime, you can find him following global news, sports and the entertainment industry.
                    </div>
                    <div style={{marginTop: '50px'}}>
                        <b>Links to resume and other work:</b>
                    </div>
                    <div className="flex align-content-end">
                        <div id="linkedin">
                            <a href="https://www.linkedin.com/in/spencer-kelley-32baa2183/" target="_blank">
                                <img src={LinkedIn} alt="LinkedIn"/>
                            </a>
                        </div>
                        <div id="media">
                            <a href="https://www.uscannenbergmedia.com/people/skelley/" target="_blank">
                                <img src={AnnenbergMedia} alt="LinkedIn"/>
                            </a>
                        </div>
                        <div id="dt">
                            <a href="https://dailytrojan.com/tag/spencer-kelley/" target="_blank">
                                <img src={DT} alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
