import React from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import Logo from '../../../assets/img/logo.svg';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.location.pathname,
        }
    }

    render() {
        const {path} = this.state;
        return (
            <>
                <img src={Logo} alt="Quick Facts on COVAX"/>
                {/*<hr style={{width: '90%'}}/>*/}
                {/*<div className="nav">*/}
                {/*    <a href="/cambodia">Cambodia</a>*/}
                {/*    |*/}
                {/*    <p>Nigeria</p>*/}
                {/*    |*/}
                {/*    <p>Ghana</p>*/}
                {/*    |*/}
                {/*    <p>Nicaragua</p>*/}
                {/*</div>*/}
                {/*<hr style={{width: '90%'}}/>*/}

                <hr style={{width: '90%'}}/>
                <>
                    <Navbar expand="md">
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="space-between w-100">
                                <NavDropdown title="Country Breakdowns" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/egypt">Egypt</NavDropdown.Item>
                                    <NavDropdown.Item href="/cambodia">Cambodia</NavDropdown.Item>
                                </NavDropdown>
                                {/*<Nav.Link href="#link">|</Nav.Link>*/}
                                <NavDropdown title="Major Developments" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/covax-seeks-further-funding">COVAX Seeks Further Funding</NavDropdown.Item>
                                    <NavDropdown.Item href="/india-curbs-vaccine-exports">India Curbs Vaccine Exports</NavDropdown.Item>
                                    <NavDropdown.Item href="/eu-astrazeneca-controversy">EU AstraZeneca Controversy</NavDropdown.Item>
                                </NavDropdown>
                                {/*<Nav.Link href="#link">|</Nav.Link>*/}
                                {/*<Nav.Link href="#link">|</Nav.Link>*/}
                                <Nav.Link href="/about">About the Author</Nav.Link>
                                <Nav.Link href="/covax-essentials">COVAX FAQ</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </>
                <hr style={{width: '90%'}}/>
            </>
        );
    }
}

export default withRouter(Header);
