import React, {Component} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import QuickFacts from '../../Library/QuickFacts';
import Airports from './img/airports.png';
import Ceremony from './img/ceremony.png';

export default class Cambodia extends Component {
    PropTypes = {
        // body: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            fact: '',
            clicked: false,
        }
    }

    onClick = (fact) => {
        this.setState({fact, clicked: true});
    }

    onMouseEnter = (fact) => {
        this.setState({fact});
    }

    onMouseLeave = () => {
        const {clicked} = this.state;
        if (!clicked) {
            this.setState({fact: ''});
        }
    }

    body = () => {
        return (
            <div className="facts">
                <bold>COVID-19 in Cambodia</bold>
                <br/>
                <br/>
                Simply put, Cambodia’s handling of the pandemic is
                {' '}
                <a href="https://ourworldindata.org/coronavirus/country/cambodia?country=~KHM">
                    a statistical success
                </a>
                {' '}
                when compared to many of its neighbors in Southeast Asia.
                <br/>
                <br/>
                Despite a population of over 16 million, Cambodia has officially registered merely <b>1,305 COVID-19
                cases (0.008% of the population)</b> and reported only <b>one death</b> -- on 11 March 2021.
                <br/>
                <br/>
                On a broader scale, the country’s rate of <b>77 cases per one million people</b> makes for
                {' '}
                <a href="https://www.csis.org/programs/southeast-asia-program/southeast-asia-covid-19-tracker-0">
                    the third-lowest among its Southeast Asian neighbors
                </a>
                , trailing only Laos and Vietnam.
                <br/>
                <br/>
                For some homegrown context,
                {' '}
                <a href="https://covidtracking.com/data/state/new-york/">
                    <b>New York</b>
                </a>
                , the American state with the population (19.5 million)
                closest in size to Cambodia’s, has reported <b>1.73 million cases (8.9% of the population), 48,370
                deaths</b> and features a current rate of <b>over 300 cases per one million people</b>.
                <br/>
                <br/>
                <b>Note:</b> Despite the positivity of Cambodia’s pandemic response, some in the global health community
                remain understandably concerned with the country’s COVID-19 testing data -- which remains vague and
                largely unpublished.
                <br/>
                <br/>
                Though lower rates of hospitalizations and deaths also represent pandemic success for the country, the
                actual state of COVID-19 in Cambodia is far less ascertainable than in wealthier countries.
                <br/>
                <br/>
                <bold>Vaccine Approval in Cambodia</bold>
                <br/>
                <br/>
                The Cambodian government approved the use of the <b>Anglo-Swedish AstraZeneca vaccine</b> as well
                as <b>China’s Sinopharm and Sinovac vaccines in February</b>.
                <br/>
                <br/>
                <div className="pic-source">
                    <img src={Airports} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: HENG SINITH/ASSOCIATED PRESS
                </div>
                <br/>
                <br/>
                <bold>Vaccine Delivery to Cambodia</bold>
                <br/>
                <br/>
                Since Cambodia approved COVID-19 vaccines in February, <b>the country has received 924,000 doses for
                distribution</b>.
                <br/>
                <br/>
                On 7 February,
                {' '}
                <b>
                    <a href="https://abcnews.go.com/Health/wireStory/cambodia-covid-19-vaccine-key-ally-china-75738331">
                        600,000 doses of the Sinopharm vaccine reached Cambodian soil
                    </a>
                    {' '}
                    through donations from China
                </b>
                , a diplomatic ally.
                <br/>
                <br/>
                Nearly a month later, on 5 March,
                {' '}
                <b>
                    <a href="https://www.unicef.org/cambodia/stories/cambodia-worlds-first-countries-receive-covax-vaccines">
                        COVAX delivered 324,000 doses of the AstraZeneca vaccine
                    </a>
                    {' '}
                    for immediate use
                </b>
                .
                <br/>
                <br/>
                Despite government approval of the Sinovac vaccine, there are no confirmed mass orders or distribution
                of this second Chinese vaccine in Cambodia.
                <br/>
                <br/>
                <bold>Vaccine Administration in Cambodia</bold>
                <br/>
                <br/>
                Unlike many COVAX countries, Cambodia’s
                {' '}
                <a href="https://ourworldindata.org/coronavirus/country/cambodia?country=~KHM">
                    data on vaccine administration
                </a>
                {' '}
                is transparent and widely available.
                <br/>
                <br/>
                Since February, the Southeast Asian country has <b>administered 161,818 of its 924,000 available vaccine
                doses</b>, translating to a <b>17.5% share of available vaccines administered</b>.
                <br/>
                <br/>
                The Cambodian government and national health authorities use the Sinopharm and AstraZeneca vaccines to
                offer <b>0.08 doses per 100 people daily</b>, leading to a rate of <b>0.97 cumulative doses per 100
                people</b>.
                <br/>
                <br/>
                In total, the country has <b>offered 1.0% of its population its first dose</b> of a COVID-19 vaccine,
                and there is no data to suggest they’ve begun offering second doses.
                <br/>
                <br/>
                <div className="pic-source">
                    <img src={Ceremony} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: UNICEF
                </div>
                <br/>
                <br/>
                <div className="questions">
                    <bold>COVAX Questions of The Week</bold>
                    <br/>
                    <br/>
                    <b>1. What is COVAX?</b>
                    <br/>
                    <br/>
                    COVAX is a global initiative founded by <a
                    href="https://www.unicef.org/supply/covax-ensuring-global-equitable-access-covid-19-vaccines">UNICEF</a>, <a
                    href="https://www.gavi.org/vaccineswork/covax-explained">Gavi, the Vaccine Alliance</a>, <a
                    href="https://www.who.int/initiatives/act-accelerator/covax">the World Health Organization</a>, <a
                    href="https://cepi.net/COVAX/">the Coalition for Epidemic Preparedness Innovations</a> and other
                    international philanthropic organizations to ensure equitable access to COVID-19 vaccines worldwide.
                    <br/>
                    <br/>
                    <b>2. Which countries will benefit from COVAX?</b>
                    <br/>
                    <br/>
                    <a href="https://www.bbc.com/news/world-55795297">
                        COVAX aims to provide free vaccinations
                    </a>
                    {' '}
                    to at least 20% of the population in the world’s 92 poorest
                    countries by the conclusion of 2021.
                    <br/>
                    <br/>
                    More than 50 middle and high-income countries have also ordered vaccines from COVAX, though they will
                    have to pay for them.
                    <br/>
                    <br/>
                    <b>3. Which COVID-19 vaccines will COVAX use?</b>
                    <br/>
                    <br/>
                    Though the organizations behind COVAX continue to invest in several emerging global vaccines (Johnson
                    and Johnson, Novavax, etc.), the majority of the COVAX vaccine supply in the first half of 2021 will
                    come from
                    {' '}
                    <a href="https://www.astrazeneca.com/media-centre/press-releases/2021/astrazeneca-advances-mass-global-rollout-of-covid-19-vaccine-through-covax.html">
                        AstraZeneca
                    </a>
                    {' '}
                    -- an Anglo-Swedish pharmaceutical and biotechnological company.
                    <br/>
                    <br/>
                    <b className="big">If you have questions about the COVAX initiative, please feel free to email us at <a
                        href="mailto:questions@quickfaxoncovax.com">questions@quickfaxoncovax.com</a>.</b>
                </div>
            </div>
        );
    }

    cases = (
        <>
            <table>
                <tr>
                    <td>Total Cases</td>
                    <td className="quick-value">1,305</td>
                </tr>
                <tr>
                    <td>7-Day Case Total (7 March - 13 March 2021)</td>
                    <td className="quick-value">294</td>
                </tr>
                <tr>
                    <td>Cases Per 1 Million</td>
                    <td className="quick-value">77</td>
                </tr>
                <tr>
                    <td>Share of Population to be Diagnosed with COVID-19</td>
                    <td className="quick-value">0.008%</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://coronavirus.jhu.edu/region/cambodia"
                >
                    Johns Hopkins University Coronavirus Resource Center
                </a>
            </div>
        </>
    );

    deaths = (
        <>
            <table>
                <tr>
                    <td>Total</td>
                    <td className="quick-value">1</td>
                </tr>
                <tr>
                    <td>Trend</td>
                    <td className="quick-value">First death reported on 11 March 2021</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://coronavirus.jhu.edu/region/cambodia"
                >
                    Johns Hopkins University Coronavirus Resource Center
                </a>
                ,
                {' '}
                <a
                    href="https://www.washingtonpost.com/world/asia_pacific/cambodia-reports-first-covid-19-death-1-year-into-pandemic/2021/03/11/f8747b7c-823a-11eb-be22-32d331d87530_story.html"
                >
                    The Washington Post
                </a>
                ,
                {' '}
                <a
                    href="https://www.latimes.com/world-nation/story/2021-03-11/cambodia-reports-first-covid-death-pandemic-one-year"
                >
                    The Los Angeles Times
                </a>
            </div>
        </>
    );

    approved = (
        <>
            <table>
                <tr>
                    <td>1. AstraZeneca</td>
                    <td className="quick-value">(United Kingdom & Sweden)</td>
                </tr>
                <tr>
                    <td>2. Sinopharm</td>
                    <td className="quick-value">(China)</td>
                </tr>
                <tr>
                    <td>3. Sinovac</td>
                    <td className="quick-value">(China)</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://www.washingtonpost.com/world/asia_pacific/first-covax-vaccines-arrive-in-cambodia-from-india/2021/03/02/5b8c2102-7b64-11eb-8c5e-32e47b42b51b_story.html"
                >
                    The Washington Post
                </a>
                ,
                {' '}
                <a
                    href="https://abcnews.go.com/Health/wireStory/cambodia-covid-19-vaccine-key-ally-china-75738331"
                >
                    ABC News
                </a>
            </div>
        </>
    );

    delivered = (
        <>
            <table>
                <tr>
                    <td>AstraZeneca</td>
                    <td className="quick-value">324,000 (through COVAX)</td>
                </tr>
                <tr>
                    <td>Sinopharm</td>
                    <td className="quick-value">600,000 (through China)</td>
                </tr>
                <tr>
                    <td>Sinovac</td>
                    <td className="quick-value">N/A</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://apnews.com/article/beijing-immunizations-coronavirus-vaccine-india-coronavirus-pandemic-c17ea1fb341d4075057e4f07dc97e43a"
                >
                    AP News
                </a>
                ,
                {' '}
                <a
                    href="https://www.unicef.org/cambodia/stories/cambodia-worlds-first-countries-receive-covax-vaccines"
                >
                    UNICEF
                </a>
                ,
                {' '}
                <a
                    href="https://abcnews.go.com/Health/wireStory/cambodia-covid-19-vaccine-key-ally-china-75738331"
                >
                    ABC News
                </a>
            </div>
        </>
    );

    administered = (
        <>
            <table>
                <tr>
                    <td>Total</td>
                    <td className="quick-value">161,818</td>
                </tr>
                <tr>
                    <td>Share of Vaccines Administered/Delivered</td>
                    <td className="quick-value">17.5%</td>
                </tr>
                <tr>
                    <td>Daily Doses Per 100 People</td>
                    <td className="quick-value">0.08</td>
                </tr>
                <tr>
                    <td>Cumulative Doses Per 100 People</td>
                    <td className="quick-value">0.97</td>
                </tr>
                <tr>
                    <td>Share of Population with One Dose</td>
                    <td className="quick-value">1.0%</td>
                </tr>
                <tr>
                    <td>Share of Population Fully Vaccinated</td>
                    <td className="quick-value">&#60;0.01%</td>
                </tr>
            </table>
            <div className="sources">Sources:
                {' '}
                <a
                    href="https://ourworldindata.org/coronavirus/country/cambodia?country=~KHM"
                >
                    Our World in Data
                </a>
            </div>
        </>
    );

    render() {
        const {body, cases, deaths, approved, delivered, administered} = this;
        const quickFacts = {cases, deaths, approved, delivered, administered};
        return (
            <div className="blog-post">
                <div className="space-between">
                    <div>
                        14 March 2021
                    </div>
                    <div className="text-right">
                        Vol. 1
                    </div>
                </div>
                <div className="title">
                    <div className="flex justify-content-center align-items-center" style={{flex: '1'}}>
                        <div>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/1200px-Flag_of_Cambodia.svg.png"
                                alt=""
                            />
                        </div>
                        <h1>
                            CAMBODIA
                        </h1>
                        <div>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/1200px-Flag_of_Cambodia.svg.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <br/>
                <QuickFacts
                    {...quickFacts}
                    map="https://www.casino-review.co/wp-content/uploads/2019/05/Cambodia-pin-Map-1000x562.jpg"
                    src="PHOTO: SHUTTERSTOCK"
                />
                <br/>
                <br/>
                {body()}
            </div>
        );
    }
}
