import React, {Component} from 'react';
import Inspected from './assets/img/inspected.png';
import Rush from './assets/img/rush.png';
import SerumeInstitute from './assets/img/serum-institute.png';

export default class IndiaCurbsVaccineExports extends Component {
    PropTypes = {
        // body: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            fact: '',
            clicked: false,
        }
    }

    onClick = (fact) => {
        this.setState({fact, clicked: true});
    }

    onMouseEnter = (fact) => {
        this.setState({fact});
    }

    onMouseLeave = () => {
        const {clicked} = this.state;
        if (!clicked) {
            this.setState({fact: ''});
        }
    }

    body = () => {
        return (
            <div className="facts">
                <div className="pic-source" style={{marginTop: '50px'}}>
                    <img src={Inspected} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: DHIRAJ SINGH/GETTY IMAGES
                </div>
                <br/>
                <br/>
                Facing a daunting rise in COVID-19 cases and deaths, India made the momentous decision in recent weeks
                to substantially cut back its export of COVID-19 vaccines.
                <br/>
                <br/>
                Let’s break down the leadup to this critical development and how it will profoundly affect COVAX and the
                global vaccination effort:
                <br/>
                <br/>
                <bold>Key Dichotomy</bold>
                <br/>
                <br/>
                As a country of nearly 1.4 billion,
                {' '}
                <a href="https://www.nytimes.com/2021/03/25/world/asia/india-covid-vaccine-astrazeneca.html">
                    India faces a series of monumental challenges
                </a>
                {' '}
                in its pursuit of vaccinating citizens against COVID-19.
                <br/>
                <br/>
                With ~50,000 new cases per day and less than 4% of its population vaccinated, the country joins the list
                of many around the world losing the war against the virus.
                <br/>
                <br/>
                Conversely, India’s production and global distribution of COVID-19 vaccines are unmitigated victories --
                for the international community.
                <br/>
                <br/>
                As the world’s
                {' '}
                <a href="https://www.axios.com/covid-coronavirus-vaccines-astrazeneca-pfizer-357c2fa9-3df8-4115-9baa-c5b2375beb44.html">
                    fourth-largest source of COVID-19 vaccines
                </a>
                , and a primary exporter of AstraZeneca doses, India plays a powerful, profitable role over the
                global vaccination effort.
                <br/>
                <br/>
                If you notice the potential issue here, you’re not alone:
                <br/>
                <br/>
                While shipping over 60 million vaccines to more than 70 countries brought goodwill and economic support
                to India, internal disdain has grown in recent weeks over the country’s vaccine distribution inequity.
                <br/>
                <br/>
                As COVID-19 cases and deaths continue to skyrocket, desperation in India has understandably surged.
                <br/>
                <br/>
                <div className="pic-source">
                    <img src={SerumeInstitute} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: EUROPE1
                </div>
                <br/>
                <br/>
                <bold>Influential Decision</bold>
                <br/>
                <br/>
                Feeling the pressure of these harsh pandemic realities, India
                {' '}
                <a href="https://www.forbesindia.com/article/global-news/india-cuts-back-on-vaccine-exports-as-infections-surge-at-home/67155/1">
                    cut back the vast majority of its COVID-19 vaccine exports
                </a>
                {' '}
                in the past two weeks.
                <br/>
                <br/>
                Without consulting the international community, the Indian government made it clear that, for the time
                being, the vast majority of India-produced vaccines will be earmarked for domestic use.
                <br/>
                <br/>
                The decision puts India, led by its Prime Minister, Narendra Modi, at odds with foreign countries that
                placed their trust and funds in the country’s rapid vaccine-producing capabilities.
                <br/>
                <br/>
                It also creates a problematic situation for India’s vaccine production companies, such as the
                internationally-recognized Serum Institute of India (SII) -- one of the world’s largest vaccine
                producers -- which currently holds 2.4 million doses of COVID-19 vaccines.
                <br/>
                <br/>
                As a major producer of the AstraZeneca vaccine, the SII agreed to many contracts with high, middle and
                low-income nations to produce and deliver the Anglo-Swedish vaccine.
                <br/>
                <br/>
                As a result, the institute’s decision to acquiesce to their government’s orders
                {' '}
                <a href="https://www.wsj.com/articles/developing-nations-seek-alternates-after-india-restricts-vaccine-exports-11616784504">
                    leaves countries worldwide in varying stages of worry
                </a>
                :
                <br/>
                <br/>
                <ul>
                    <li>
                        Who will make up for the lack of India-produced vaccines?
                    </li>
                    <li>
                        How long will delays last?
                    </li>
                    <li>
                        What will happen to doses earmarked for COVAX?
                    </li>
                </ul>
                <div className="pic-source">
                    <img src={Rush} alt="" style={{maxWidth: '1080px'}}/>
                    <br/>
                    PHOTO: REUTERS
                </div>
                <br/>
                <br/>
                <bold>Impact on COVAX</bold>
                <br/>
                <br/>
                While it’s unclear at this time how the shortage of India-produced vaccines will influence wealthier
                countries, the effects of India’s actions on COVAX are clear:
                <br/>
                <br/>
                According to the
                {' '}
                <a href="https://www.who.int/news/item/25-03-2021-covax-updates-participants-on-delivery-delays-for-vaccines-from-serum-institute-of-india-(sii)-and-astrazeneca">
                    World Health Organization
                </a>
                , deliveries of AstraZeneca vaccines to lower-income, COVAX countries from the SII “will be delayed in
                March and April.”
                <br/>
                <br/>
                For the 64 lower-income countries waiting on COVAX shipments, this means
                the 40 million expected doses by March and the up to 50 million doses scheduled for April are subject to
                postponement.
                <br/>
                <br/>
                For the time being, these COVAX countries will continue to distribute and administer the 28 million (out
                of the above 90) AstraZeneca (a.k.a. “COVISHIELD”) vaccines they’ve received as of yet.
                <br/>
                <br/>
                Time will tell how backers of COVAX might account for this shortfall of doses, but
                {' '}
                <a href="https://www.reuters.com/article/health-coronavirus-india-covax/covax-expects-full-vaccine-supplies-from-indias-serum-in-may-says-unicef-idUSL4N2LP066">
                    reports from UNICEF
                </a>
                {' '}
                indicate the remaining 62 million India-produced doses might come by May.
                <br/>
                <br/>
                <bold>COVAX Questions of The Week</bold>
                <br/>
                <br/>
                <b>1. Has the U.S. approved the AstraZeneca vaccine?</b>
                <br/>
                <br/>
                As of 28 March, the AstraZeneca vaccine is not approved for administration in the United States.
                <br/>
                <br/>
                <b>2. Has COVAX delivered vaccines to African countries?</b>
                <br/>
                <br/>
                Yes -- COVAX has delivered over
                {' '}
                <a href="https://www.afro.who.int/pt/node/14219">
                    16 million COVID-19 vaccines to 27 African countries
                </a>
                .
                <br/>
                <br/>
                <b className="big">If you have questions about the COVAX initiative, please feel free to email us at <a
                    href="mailto:questions@quickfaxoncovax.com">questions@quickfaxoncovax.com</a>.</b>
            </div>
        );
    }

    render() {
        const
            {body} = this;
        return (
            <div className="blog-post">
                <div className="space-between">
                    <div>
                        28 March 2021
                    </div>
                    <div className="text-right">
                        Vol. 3
                    </div>
                </div>
                <div className="title">
                    <h1>India Curbs Vaccine Exports</h1>
                </div>
                {body()}
            </div>
        );
    }
}
